<template>
    <section class="badges">
        <div class="row my-2 py-2 border-top border-bottom">
            <div class="content-wrapper">
                <!-- <div v-if="loading">loading</div> -->
                <div class="row">
                    <div v-if="product" class="col-md-6 col-sm-12 limit-x">
                        <!-- affichage image avec les carousels -->
                        <!-- <product-zoomer-ailem v-if="photo"
                        :images="photo"
                        :key="keytest"                      
                         /> -->
                         <template v-if="product.images.length>0">
                             <!-- l'image doit avoir un min width et min height pour qu'elle marche sur mobile -->
                            <picture>
                                <source :srcset="product.images[0].content.webpImage" type="image/webp">
                                <img class="img-fluid background-img" :src="product.images[0].content.webpImage" alt="image du produit" loading="lazy">
                            </picture>
                         </template>
                         <img v-else class="img-fluid background-img" src="@/assets/images/import/default-image.jpg" alt="image du produit" loading="lazy">

                         <div class="col-md-12 mt-5" style="max-width:100%">
                             <div v-if="Otherproduct != null && Otherproduct.length > 0">
                                 <h4>Les couleurs disponibles similaires</h4>
                                 <div class="scrollcards">
                                     <span class="pointer" v-for="(item,index) in Otherproduct " :key="index" @click="goproduct(item)">
                                        <picture v-if="item.image.length>0">
                                            <source class="other-product" :srcset="item.image[0].content.webpImage" type="image/webp">
                                            <img class="other-product" :src="item.image[0].content.originalImage" alt="Card image cap" loading="lazy">
                                        </picture>
                                        <img v-else class="other-product" src="@/assets/images/import/default-image.jpg" alt="image" loading="lazy">
                                    </span>
                                 </div>
                             </div>
                         </div>
                    </div>
                    <div class="col-md-6 col-sm-12" v-if="product">
                        <div class="m-4">
                            <h3>{{product.name}}</h3>
                            <p>{{ product.description}}</p>
                            <h3> {{roundNumber(product.price * 1.2)}}€ 
                            </h3>
                            <div class="my-5">
                            <template v-if="product.stock <= 0">
                                <div>
                                <b-alert show variant="warning"><a class="alert-link">Stock epuisé</a></b-alert>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <b-form-input  v-model="product.quantity" type="range" min="1" @change="getValue(product.quantity)" :max="product.stock"></b-form-input>
                                    <div class="mt-2 mb-5">Quantité: {{ quantite}}</div>
                                    <button class="btn btn-gradient-primary " @click="addProduct()">Ajouter au panier</button>
                                </div>
                            </template>
                                <!-- <button class="btn btn-gradient-secondary">0</button> -->
                            </div>
                            <!-- <p>Pourquoi acheter nos produits?</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex fugit ipsa asperiores obcaecati beatae cupiditate quam illo amet temporibus, quidem iure iusto possimus, soluta fugiat alias animi enim ipsum tempora!</p>
                            <ul class="checked-icon">
                                <li class="text-muted">qualité premium</li>
                                <li class="text-muted">qualité premium</li>
                                <li class="text-muted">qualité premium</li>
                            </ul> -->
                        </div>
                    </div>
                    <div v-else class="jumping-dots-loader">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- liste des produits-card -->
        <div class="row mb-4">
            <div class="col-md-12">
                <div class="content-wrapper d-flex justify-content-between">
                    <h3>Autres produits</h3>
                    <router-link class="btn btn-outline-primary btn-minimalist btn-sm pt-3" to="/boutique"> Voir tous les produits</router-link>
                </div>
                    <product-card
                    scrollcards
                    entitytype="products"
                    title="name"
                    price="price"
                    description="description"
                    />
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import productCard from '../components/element/product-card.vue'
import {priceMixin} from '../pages/mixins/price.js'
// import productZoomerAilem from '../components/element/product-zoomer-ailem.vue'
// import products from '../json/products.json'

export default {
    name: 'product',
    mixins:[priceMixin],
    components:{
        productCard,
        // productZoomerAilem
    },
    computed:{
        quantite:function(){
            return this.q
        }
    },
    props:{
        ulid:String
    },
    data () {
        return {
            product: null,
            Otherproduct: null,
            q:1,
            keytest:1,
            photo:null,    
            loading:true
        }
    },
    watch: {
        // ulid(newUlid){
        //     this.getProduct(newUlid)
        // }
    },
    methods:{
        getProduct(ulid){
            let url = "products/" + ulid
            axios.get(url)
                .then(
                    resgetProducts => {
                        this.product = resgetProducts.data
                        this.product.quantity = 1
                        this.photo = resgetProducts.data.images
                        this.getAllProduct()
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        getAllProduct(){
            let url = "products"
            axios.get(url)
                .then(
                    resgetProducts => {
                        this.Otherproduct = resgetProducts.data.result
                        this.Otherproduct = this.Otherproduct.filter(res =>{
                            return this.product.name == res.name && this.product.ulid != res.ulid && res.isactive
                        })
                    }
                )
                .catch(
                    errgetProducts => {
                        console.log(errgetProducts)
                    }
                )
        },
        addProduct(){
            this.$store.dispatch('add_product', this.product)
            this.$snotify.success("Produits ajoutés au panier", {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                });
            this.$router.push(
                {
                name: 'shop'
                }
            )
        },
        getValue(item){
            this.q = item
        },
        goproduct(item) {
            this.$router.replace({
                name:"product",
                params: {
                    ulid: item.ulid
                }
            })
        }
    },
    beforeCreate() {
        // mettre X-Auth-token sur toutes les requêtes de la page
      delete axios.defaults.headers.common['X-AUTH-TOKEN']
    },
    created () {
        this.getProduct(this.ulid)
    },
    beforeRouteUpdate (to, from, next) {
        this.getProduct(to.params.ulid)
        this.keytest = this.keytest + 1
        next()
    },
}
</script>
<style scoped>
.background-img {
    min-width: 2rem;
    min-height: 2rem
}
.scrollcards {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
}
.other-product {
    display: inline-block;
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
</style>